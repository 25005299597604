import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=3118e908&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=ts&"
export * from "./Gallery.vue?vue&type=script&lang=ts&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=3118e908&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3118e908",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProductCard: require('/var/www/packages/theme/components/molecules/ProductCard.vue').default,AtomsKECarousel: require('/var/www/packages/theme/components/atoms/KECarousel.vue').default,AtomsErrorBoundary: require('/var/www/packages/theme/components/atoms/ErrorBoundary.vue').default,AtomsDesktopOnly: require('/var/www/packages/theme/components/atoms/DesktopOnly.vue').default,AtomsMobileOnly: require('/var/www/packages/theme/components/atoms/MobileOnly.vue').default})
