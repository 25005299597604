





































import {
  defineComponent,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow.js';
import type { Product } from '~/components/molecules/ProductCard.vue';
import { getCustomFieldByKey } from '~/helpers/utils';

type ProductCarousel = {
  id: number;
  number_of_columns: number;
  code: string;
  products?: Product[];
  product_tags?: {
    products: Product[];
  };
  custom_fields: [
    {
      key: 'show_add_to_cart_mobile';
      value: boolean;
    },
    {
      key: 'show_add_to_cart_desktop';
      value: boolean;
    }
  ];
};

export default defineComponent({
  name: 'Gallery',
  props: {
    data: {
      type: Object as PropType<ProductCarousel>,
      default: () => ({})
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();
    const productCarousel = (props.data || {}) as ProductCarousel;

    const productsPerView = ref(productCarousel.number_of_columns / 3);

    const products = ref(
      productCarousel.products?.sort(
        (a: Product, b: Product) => a.sequence - b.sequence
      ) || productCarousel.product_tags?.[0].products
    );
    const hideAddToCartButtonMobile = getCustomFieldByKey(
      productCarousel.custom_fields,
      'hide_cart_button_mobile'
    )?.checkbox;
    const hideAddToCartButtonDesktop = getCustomFieldByKey(
      productCarousel.custom_fields,
      'hide_cart_button_desktop'
    )?.checkbox;

    return {
      isDesktop,
      productCarousel,
      productsPerView,
      products,
      hideAddToCartButtonMobile,
      hideAddToCartButtonDesktop
    };
  }
});
